if (module.hot) {
	module.hot.accept();
}

import 'bootstrap';
import 'slick-carousel';
import 'webpack-jquery-ui/css';
import 'webpack-jquery-ui/slider';
import 'webpack-jquery-ui/selectmenu';

const preventFoloow = () => {
	$('a[data-no-folow]').on('click', e => e.preventDefault())
}

const addToCart = e => {
	e.preventDefault();
	e.stopPropagation();
	console.log('Add to cart', e)
}

$(document).ready(() => {

	if (!!$('.products-slider__item__button') && $('.products-slider__item__button').length > 0) {
		$('.products-slider__item__button').each(function () {
			$(this).on('click', function (e) {
				addToCart(e)
			})
		});
	}


	// Main page banner slider
	if (!!$('.main-banner__slider') && $('.main-banner__slider').length > 0) {
		$('.main-banner__slider').slick({
			dots: true,
			fade: true,
			nextArrow: $('.main-banner__slick-next'),
			prevArrow: $('.main-banner__slick-prev'),
		})
	}

	// Popular products slider
	if (!!$('#popularProducts') && $('#popularProducts').length > 0) {
		let slider_options = {
			prevArrow: $('#popularProductsPrev'),
			nextArrow: $('#popularProductsNext'),
			slidesPerRow: 3,
			rows: 2,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesPerRow: 2,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesPerRow: 1,
						rows: 1,
					}
				}
			]
		};

		$('#popularProducts').slick(slider_options)
	}

	// New products slider
	if (!!$('#newProducts') && $('#newProducts').length > 0) {
		let slider_options = {
			prevArrow: $('#newProductsPrev'),
			nextArrow: $('#newProductsNext'),
			slidesPerRow: 3,
			rows: 2,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesPerRow: 2,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesPerRow: 1,
						rows: 1,
					}
				}
			]
		};

		$('#newProducts').slick(slider_options)
	}

	// Special offers slider
	if (!!$('#specialOffers') && $('#specialOffers').length > 0) {
		let slider_options = {
			prevArrow: $('#specialOffersPrev'),
			nextArrow: $('#specialOffersNext'),
			slidesPerRow: 4,
			rows: 2,
			responsive: [
				{
					breakpoint: 1440,
					settings: {
						slidesPerRow: 3,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesPerRow: 2,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesPerRow: 1,
						rows: 1,
					}
				}
			]
		};

		$('#specialOffers').slick(slider_options)
	}

	// Our achievements slider
	if (!!$('#ourAchievements') && $('#ourAchievements').length > 0) {
		let slider_options = {
			speed: 1500,
			slidesPerRow: 4,
			responsive: [
				{
					breakpoint: 1440,
					settings: {
						slidesPerRow: 3,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesPerRow: 2,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesPerRow: 1,
						rows: 1,
					}
				}
			]
		};

		$('#ourAchievements').slick(slider_options)
	}

	// Products page

	if (!!$('#slider-range') && $('#slider-range').length > 0) {
		let options = {
			range: true,
			slide: function(event, ui) {
				$('#min-value').val(ui.values[0]);
				$('#max-value').val(ui.values[1]);
			}
		};
		if(!!$('#min-amount') && $('#min-amount').length > 0
			&& !!$('#max-amount') && $('#max-amount').length > 0) {
			let min_amount = $('#min-amount').val();
			let max_amount = $('#max-amount').val();
			let min_value = $('#min-value').val();
			let max_value = $('#max-value').val();
			if (min_amount && max_amount) {
				options.min = +min_amount;
				options.max = +max_amount;
				options.values = [+min_value, +max_value];
			}
		}

		console.log(options)
		$('#slider-range').slider(options);
	}

	if (!!$('#min-amount') && $('#min-amount').length > 0) {
		$('#min-amount').val($('#slider-range').slider('values', 0));
	}

	if (!!$('#max-amount') && $('#max-amount').length > 0) {
		$('#max-amount').val($('#slider-range').slider('values', 1));
	}

	if (!!$('.customSelectSortBy') && $('.customSelectSortBy').length > 0) {
		$(".customSelectSortBy").selectmenu();
	}

	if (!!$('#showBy') && $('#showBy').length > 0) {
		$("#showBy").selectmenu();
	}

	if (!!$(".products-section__body__mobile-filter-button") && $(".products-section__body__mobile-filter-button").length > 0) {
		$(".products-section__body__mobile-filter-button").on('click', function (e) {
			$(this).toggleClass('filter-hidden');
			if (!!$(".products-section__body__filter") && $(".products-section__body__filter").length > 0) {
				$(".products-section__body__filter").slideToggle('fast');
			}
		})
	}

	// Product Card
	// if (!!$('.product-card-slider__nav') && $('.product-card-slider__nav').length > 0) {
	// 	$('.product-card-slider__nav').slick({
	// 		slidesToShow: 4,
	// 		slidesToScroll: 1,
	// 		// focusOnSelect: true,
	// 		vertical: true,
	// 		verticalSwiping: true,
	// 		responsive: [
	// 			{
	// 				breakpoint: 768,
	// 				settings: {
	// 					slidesToShow: 3,
	// 					vertical: false,
	// 					verticalSwiping: false,
	// 				}
	// 			}
	// 		]
	// 	});
	// }

	// Recommended Products
	if (!!$('#recommendedProducts') && $('#recommendedProducts').length > 0) {
		let slider_options = {
			arrows: false,
			dots: false,
			slidesPerRow: 4,
			rows: 1,
			responsive: [
				{
					breakpoint: 1440,
					settings: {
						slidesPerRow: 3,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesPerRow: 2,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesPerRow: 1,
					}
				}
			]
		};

		$('#recommendedProducts').slick(slider_options)
	}

	// Header mobile menu
	if (!!$('.mobile-header__menu__button') && $('.mobile-header__menu__button').length > 0
		&& !!$('.mobile-header__nav--wrapper') && $('.mobile-header__nav--wrapper').length > 0
		&& !!$('.mobile-header__nav__close') && $('.mobile-header__nav__close').length > 0) {

		$('.mobile-header__menu__button').on('click', function () {
			$('body').addClass('modal-open');
			$('.mobile-header__nav--wrapper').fadeIn('200');
		});

		$('.mobile-header__nav__close').on('click', function () {
			$('body').removeClass('modal-open');
			$('.mobile-header__nav--wrapper').fadeOut('200');
		});
	}

	preventFoloow();

});
